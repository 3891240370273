import { mergeWith } from 'lodash-es'
import { mergeReferences } from './utils'

class TempFesClientImpl {
  constructor(dataProvider) {
    this.dataProvider = dataProvider
  }

  _aggregateData(datasetConfigs, datasetsData) {
    return datasetsData.reduce(
      (response, { items, totalCount, uniqueFieldValues, error }, index) => {
        if (error) {
          response.recordsInfoByDataset.push({ error })
          return response
        }
        response.recordsInfoByDataset.push({
          itemIds: items.map(({ _id }) => _id),
          totalCount,
        })
        const collectionId = datasetConfigs[index].collectionId
        response.recordsByCollection[collectionId] = items.reduce(
          (acc, record) => {
            const existingRecord = acc[record._id]
            acc[record._id] = mergeWith(existingRecord, record, mergeReferences)

            return acc
          },
          response.recordsByCollection[collectionId] || {},
        )

        response.uniqueFieldValuesByCollection[collectionId] = {
          ...response.uniqueFieldValuesByCollection[collectionId],
          ...uniqueFieldValues,
        }

        return response
      },
      {
        recordsInfoByDataset: [],
        recordsByCollection: {},
        uniqueFieldValuesByCollection: {},
      },
    )
  }

  async fetchInitialData(datasetConfigs) {
    const datasetsData = await Promise.all(
      datasetConfigs.map(
        async ({
          collectionId,
          filter,
          sort,
          offset,
          length,
          includes,
          uniqueFieldValues,
        }) => {
          try {
            return await this.fetchData({
              collectionId,
              filter,
              sort,
              offset,
              length,
              includes,
              uniqueFieldValues,
            })
          } catch (error) {
            return {
              error: fixWixDataError(error),
            }
          }
        },
      ),
    )

    return this._aggregateData(datasetConfigs, datasetsData)
  }

  async fetchData({
    collectionId,
    filter,
    sort,
    offset,
    length,
    includes,
    uniqueFieldValues,
  }) {
    const [data, uniqueFieldValuesResponse] = await Promise.all([
      this.dataProvider.get({
        collectionId,
        filter,
        sort,
        offset,
        length,
        includes,
      }),
      uniqueFieldValues
        ? this.fetchUniqueValues({ collectionId, fieldKeys: uniqueFieldValues })
        : {},
    ])

    return {
      ...data,
      uniqueFieldValues: uniqueFieldValuesResponse,
    }
  }

  async remove({ collectionId, recordId }) {
    return this.dataProvider.remove({
      collectionId,
      recordId,
    })
  }

  async save({ collectionId, record, includeReferences }) {
    return this.dataProvider.save({
      collectionId,
      record,
      includeReferences,
    })
  }

  async fetchUniqueValues({ collectionId, fieldKeys }) {
    const uniqueValuesArray = await Promise.all(
      fieldKeys.map(fieldKey =>
        this.dataProvider.distinct({ collectionId, fieldKey }),
      ),
    )
    return uniqueValuesArray.reduce((acc, { _items: values }, index) => {
      acc[fieldKeys[index]] = values
      return acc
    }, {})
  }
}

function fixWixDataError({ message, code }) {
  // WixData lib creates an error with broken prototype and sends it to sentry.
  // This reslts empty errors in sentry without any clues on what did go wrong.
  // A perfect example of what the lib should not to do for following single responsibility principle.
  const error = new Error(message)
  error.code = code

  return error
}

export default TempFesClientImpl
